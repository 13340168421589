import {getList, postReport} from "@/api/exp/StudentExperimentApi";

/**
 学生实验model
 */
class StudentExperimentModel {
    // 获取列表
    static async getList(param) {
        let [data] = await getList(param)
        return data.data;
    }

    // 提交实验报告
    static async postReport(id, content) {
        let [data] = await postReport({
            id,
            labreportcontent: content
        })
        if (data.code === 20000) {
            return true
        }
    }


}

export {StudentExperimentModel}
