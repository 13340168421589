import {request_async} from "@/utils/requestAsync";
import { API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表
export async function getList(param) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentexperiment/myExperiment`, "get", param);
}

// 提交实验报告
/**
 *
 * @param param {{id,labreportcontent}}
 * @returns {Promise<unknown>}
 */
export async function postReport(param) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentexperiment/addExperimentReport`, "post_json", param);
}
