<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary"
               @click=" dialogVisible=true">
      上传文件
    </el-button>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <el-upload
        style="width: 50%"
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :on-error="handleError"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        :http-request="uploadRequest"
        class="editor-slide-upload"
        action="void"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        确 定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
  import {BaseUploadModel} from '@/model/BaseUploadModel'

  export default {
    name: 'EditorSlideUpload',
    props: {
      color: {
        type: String,
        default: '#1890ff'
      }
    },
    data() {
      return {
        dialogVisible: false,
        listObj: {},
        fileList: [],
        errList: "",
      }
    },
    methods: {
      checkAllSuccess() {
        return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
      },
      handleSubmit() {
        const arr = Object.keys(this.listObj).map(v => this.listObj[v])
        if (!this.checkAllSuccess()) {
          // 判断是第几个的问题
          Object.keys(this.listObj).forEach((item, index) => {
            if (!this.listObj[item].hasSuccess) {
              this.errList = this.errList + (index + 1) + ','
            }
          })
          this.$message('第' + this.errList + '个文件上传失败，请删除这些，保存后重新再上传')
          return
        }
        this.$emit('successCBK', arr)
        this.listObj = {}
        this.fileList = []
        this.dialogVisible = false
      },
      handleSuccess(response, file) {
        const uid = file.uid
        const objKeyArr = Object.keys(this.listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            this.listObj[objKeyArr[i]].url = response.data
            this.listObj[objKeyArr[i]].hasSuccess = true
            return
          }
        }
      },
      handleError(file) {
        return true;
      },
      handleRemove(file) {
        const uid = file.uid
        const objKeyArr = Object.keys(this.listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            delete this.listObj[objKeyArr[i]]
            return
          }
        }
      },
      beforeUpload(file) {
        const _self = this
        // 判断大小和文件格式 todo 多个选择时
        let fileType = BaseUploadModel.getFileType(file)[0]
        let fileTypeLast = "";
        switch (fileType) {
          case 'image':
            if (!BaseUploadModel.checkFileSize(BaseUploadModel.fileSizeLimit.questionContentImg, file)) {
              return false
            }
            fileTypeLast = fileType;
            break
          case 'video':
            if (!BaseUploadModel.checkFileSize(BaseUploadModel.fileSizeLimit.questionContentVideo, file)) {
              return false
            }
            fileTypeLast = fileType;
            break
          case 'audio':
            if (!BaseUploadModel.checkFileSize(BaseUploadModel.fileSizeLimit.questionContentAudio, file)) {
              return false
            }
            fileTypeLast = fileType;
            break
          default:
            if (!BaseUploadModel.checkFileSize(BaseUploadModel.fileSizeLimit.questionContentOther, file)) {
              return false
            }
            fileTypeLast = "other";
            break
        }
        const _URL = window.URL || window.webkitURL
        const fileName = file.uid
        this.listObj[fileName] = {}
        _self.listObj[fileName] = {hasSuccess: true, uid: file.uid}
      },
      async uploadRequest(upload) {
        let $this = this;
        let file = upload.file;
        return await new Promise((resolve, reject) => {
          BaseUploadModel.qiNiuUpload(file, {
            next: (result) => {
            },
            error: (errResult) => {
              console.log(errResult);
              resolve(false)
              alert("上传失败");
            },
            complete: (result) => {
              let domain = BaseUploadModel.getBucketDomain(file);
              let url = domain + "/" + result.key + "";
              resolve({data: url});
            }
          });
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .editor-slide-upload {
    margin-bottom: 20px;

    ::v-deep .el-upload--picture-card {
      width: 100%;
    }
  }
</style>
