<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}" v-loading="loadJs">
    <textarea :id="tinymceId" class="tinymce-textarea"/>
        <div class="editor-custom-btn-container" v-show="showUploadFile">
          <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK"/>
        </div>
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import editorImage from './components/EditorImage'
import plugins from './plugins'
import toolbar from './toolbar'
import load from './dynamicLoadScript'
import {BaseUploadModel} from '@/model/BaseUploadModel'
import {editorShowUploadList} from "@/config/ExpWebConfig";

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
const tinymceCDN = 'https://resouce.cdzyhd.com/tinymce-all-in-one-4.9.3/tinymce.min.js'
const powerPasteCDN = 'https://resouce.cdzyhd.com/powerpaste-2.1.10/plugin.min.js'
export default {
  name: 'Tinymce',
  components: {editorImage},
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },
  data() {
    return {
      editorShowUploadList,
      loadJs: false,
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      showUploadFile:false,
      languageTypeList: {
        'zh': 'zh_CN',
      }
    }
  },
  computed: {
    language() {
      return 'zh_CN'
    },
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
            window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    language() {
      this.destroyTinymce()
      this.$nextTick(() => this.initTinymce())
    }
  },
  mounted() {
    this.init()
    // 获取当前学校id和用户角色 判断是否显示上传组件
    let expUserInfo=JSON.parse(sessionStorage.getItem('expUserInfo'))
    let schoolId=expUserInfo["schoolId"]
    let userRole=expUserInfo["rolename"]
    if(this.editorShowUploadList.indexOf(schoolId)>-1&&userRole==='teacher'){
      this.showUploadFile=true
    }
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      this.loadJs = true
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        this.loadJs = false
        if (err) {
          this.$message.error(err.message)
          return
        }
        this.initTinymce()
      })
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        language: this.language,
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        external_plugins: {'powerpaste': powerPasteCDN},
        plugins: plugins,
        end_container_on_empty_block: true,
        // CONFIG: Paste
        paste_retain_style_properties: 'all',
        paste_word_valid_elements: '*[*]',        // word需要它
        paste_data_images: true,                  // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false,     // 插入word文档需要该属性
        paste_webkit_styles: 'all',
        paste_merge_formats: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        powerpaste_allow_local_images: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        // 整合七牛上传
        images_dataimg_filter(img) {
          return img
        },

        images_upload_handler(blobInfo, success, failure, progress) {
          progress(0);
          let file = new File([blobInfo.blob()], BaseUploadModel.getUuid() + ".png", {type: "image/png"})
          BaseUploadModel.qiNiuUpload(file, {
            next: (result) => {
            },
            error: (errResult) => {
              console.log(errResult);
              alert("上传失败");
            },
            complete: (result) => {
              let domain = BaseUploadModel.getBucketDomain(file);
              let url = domain + "/" + result.key + "";
              success(url);
              progress(100);
            }
          });
        },
      }).then(resolve => {// 初始化完成后
        console.log("Tinymce初始化完毕")
        this.$emit("initEd")
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      arr.forEach(v => {
            // 根据不同文件类型添加不同html
            let nameArr = v.url.split('.')
            let fileSuffix = nameArr[nameArr.length - 1]
            let fileName = v.url.split("/")[3];
            if (BaseUploadModel.fileSuffixEnum.image.indexOf(fileSuffix) > -1) {
              window.tinymce.get(this.tinymceId).insertContent(`<img class="tinymce-upload-img" src="${v.url}" >`)
            } else if (BaseUploadModel.fileSuffixEnum.video.indexOf(fileSuffix) > -1) {
              window.tinymce.get(this.tinymceId).insertContent(`<video class="tinymce-upload-video" src="${v.url}" controls="controls">`)
            } else if (BaseUploadModel.fileSuffixEnum.audio.indexOf(fileSuffix) > -1) {
              window.tinymce.get(this.tinymceId).insertContent(`<embed height="50" width="100" src="${v.url}"/>`)
            } else {
              window.tinymce.get(this.tinymceId).insertContent(`<a class="tinymce-upload-other" href="${v.url}" >${fileName}</a>`)
            }
          }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
