<template>
  <div class="page-container">
    <div class="list" v-loading="listLoading">
      <div class="s-li" v-for="(sItem,sIndex) in list" v-if="list.length>0">
        <div class="s-title flex flex-start"><i class="round"></i>{{ sItem.name }}</div>
        <div class="li-box flex flex-start flex-wrap">
          <div class="li flex flex-dr flex-center" v-for="(item,index) in sItem.studentExperimentPreVoList">
            <img :src="item.icon" alt="" class="avatar">
            <span class="li-title">{{ item.experimentname }}</span>
            <div class="date" v-if="item.experimentstatus!==2">开始时间：<span>{{ item.openstarttime | dateFormat }}</span>
            </div>
            <div class="date" v-if="item.experimentstatus!==2">结束时间：<span>{{ item.openendtime | dateFormat }}</span>
            </div>
            <div class="score">实验成绩：
              <span v-if="item.isfinishexp&&item.experimentstatus!==0">{{
                  item.experimentscore | numberFormat
                }}分</span>
              <span v-if="!item.isfinishexp">未完成实验</span>
            </div>
            <div class="teacher">授课老师：{{ item.teachername }}</div>
            <div class="button">
              <template v-if="item.experimentstatus===0">
                <el-button type="primary" size="small" disabled>填写实验报告</el-button>
              </template>
              <!--如果老师未批阅实验报告分可以重复修改，如果未批阅就只能查看 进行中和已完成实验可以-->
              <template v-if="item.experimentstatus!==0">
                <!--240606改为西财的实验批改后也能填写-->
                <el-button type="primary" size="small" @click="clickWriteReportBtn(item,index,sIndex)"
                           v-if="!item.labreportchecktime||(userInfo.schoolId==='59977128740917248')">填写实验报告
                </el-button>
                <el-button type="primary" size="small" @click="clickViewReportBtn(item,index,sIndex)"
                           v-else>查看实验报告
                </el-button>
<!--                <el-button type="primary" size="small" @click="clickWriteReportBtn(item,index,sIndex)"-->
<!--                           v-if="!item.labreportchecktime">填写实验报告-->
<!--                </el-button>-->
<!--                <el-button type="primary" size="small" @click="clickViewReportBtn(item,index,sIndex)"-->
<!--                           v-if="item.labreportchecktime">查看实验报告-->
<!--                </el-button>-->
              </template>
            </div>
          </div>
        </div>
      </div>
      <!--无列表提示-->
      <div class="no-list flex flex-dr flex-center" v-if="list.length===0&&listLoaded">
        <div>
          <img src="@/assets/icon/experiment-no-list.png" alt="">
          <div>没有{{ statusText[queryStatus] }}的实验</div>
        </div>
      </div>
    </div>
    <!--查看实验报告弹窗-->
    <el-dialog
        title="实验报告"
        :visible.sync="view.dialog"
        :close-on-click-modal="false"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container report-view-container">
        <div v-html="view.content" class="html-view limit-height"></div>
      </div>
    </el-dialog>
    <!--写实验报告弹窗-->
    <el-dialog
        :close-on-click-modal="false"
        :title="write.experiment.experimentname"
        :visible.sync="write.dialog"
        @close="closeWriteDialog"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <tinymce
            ref="tinymce_content"
            v-model="write.content"
            :height="300"
            @initEd="onWriteTinymceInit"
        />
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickSendReportBtn" size="small" :loading="write.actionPosting">
          提交实验报告
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {StudentExperimentModel} from "@/model/exp/StudentExperimentModel";
import {dateFormat, numberFormat} from "@/filter/filter";
import elDragDialog from "@/directive/el-drag-dialog";
import Tinymce from "@/components/Tinymce";
import {msg_confirm, msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";

export default {
  name: "studentExpList",
  filters: {
    dateFormat: dateFormat,
    numberFormat: numberFormat
  },
  components: {Tinymce},
  directives: {
    elDragDialog
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      statusText: ['未开始', '进行中', '已结束'],
      queryStatus: this.$route.query["status"],
      list: [],
      listLoading:false,
      listLoaded: false,
      view: {
        dialog: false,
        content: ""
      },
      write: {
        index: 0,
        sindex: 0,
        title: "",
        actionPosting: false,
        experiment: {
          experimentname: ""
        },
        dialog: false,
        content: "",
      }
    }
  },
  async mounted() {
    this.listLoading=true
    this.list = await StudentExperimentModel.getList({
      experimentstatus: this.queryStatus,
      schoolId: this.userInfo.schoolId
    }).catch(err=>{this.listLoading=false})
    this.listLoaded = true
    this.listLoading=false

    console.log(this.userInfo)
  },
  methods: {
    // 点击填写实验报告按钮
    clickWriteReportBtn(item, index, sindex) {
      if (!item.isfinishexp) {
        msg_err("您尚未完成该实验，无法提交报告!如果您确认已完成，请刷新浏览器后再试！")
        return
      }

      this.write.dialog = true
      this.write.experiment = item
      this.write.index = index
      this.write.sindex = sindex
      this.write.content = item.labreportcontent ? item.labreportcontent : ""

      // 适应弹窗打开多个tinymce的情况，每次关闭dialog时销毁，再次打开时初始化
      if (this.$refs['tinymce_content']) {
        this.$refs['tinymce_content'].init()
      }
    },
    // 当tinymce初始化完成
    onWriteTinymceInit() {
      // 手动赋值，当内容为空时，多个编辑框内容也会变化
      this.$refs['tinymce_content'].setContent(this.write.content)
    },
    // 关闭实验报告填写弹窗
    closeWriteDialog() {
      // 适应弹窗打开多个tinymce的情况，每次关闭dialog时销毁，再次打开时初始化
      this.$refs['tinymce_content'].destroyTinymce()
    },
    // 点击查看实验报告按钮
    clickViewReportBtn(item) {
      this.view.dialog = true
      this.view.content = item.labreportcontent
    },
    // 点击提交实验报告按钮
    async clickSendReportBtn() {
      if (this.write.content === "") {
        msg_err("请填写内容后再提交!")
        return
      }
      let msgText = "确认要提交吗？在老师批改前，您可以多次修改。"
      if (await msg_confirm(msgText)) {
        this.$set(this.write, "actionPosting", true)
        let data = await StudentExperimentModel.postReport(this.write.experiment.id, this.write.content).catch(() => {
          this.$set(this.write, "actionPosting", false)
        })
        if (data) {
          msg_success("提交成功")
          this.$set(this.list[this.write.sindex]["studentExperimentPreVoList"][this.write.index], "labreportcontent", this.write.content)
          this.write.dialog = false
          this.write.content = ""
          this.$set(this.write, "actionPosting", false)

        }
      }

    }
  }
}
</script>
<style>
.report-view-container.dialog-container img {
  max-width: 100% !important;
}
</style>
<style scoped lang="less">
.page-container {
  padding: 0;
}

#page-container {
  > .page-container {
    background-color: #ebeff2;
    padding: 0;
  }
}

.s-li {
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;

  .s-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .round {
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      display: inline-block;
      background-color: #2d7bc9;
      margin-right: 10px;
    }
  }

  .li-box {
    .li {
      box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.16);
      padding: 10px;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 5px;

      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 6px;
        background-color: #cecece;
      }

      .li-title {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
      }

      .date, .score {
        font-size: 13px;
        color: #666;
        margin-top: 8px;
        width: 100%;

        span {
          color: #F59528;
        }
      }

      .score span {
        color: #78de80;
      }

      .teacher {
        font-size: 13px;
        color: #666;
        margin-top: 8px;
        width: 100%;
      }

      .button {
        margin-top: 8px;
      }
    }
  }
}

.no-list {
  min-height: calc(100vh - 130px);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  color: #666;

  img {
    width: 378px;
    height: 250px;
    margin: 0 auto;
  }

  > div > div {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #C1C1C1;
    margin-top: 20px;
  }
}
</style>
